import React, { useState, useEffect } from "react";
import { AccountInfo, getAccountByUserId, getAccountInfoById } from "../../../api/companyInfoApi";
import { upsertAccountInfo } from "../../../api/companyInfoApi";
import { useUser } from "../../../Components/context/UserContext";
import { useNavigate } from "react-router-dom";

const AccountDetailsEdit: React.FC = () => {
  const { userIdentity } = useUser();
  const navigate = useNavigate(); // Set up navigate for routing

  // Initial state for form fields
  const [formData, setFormData] = useState<AccountInfo>({
    accountId: 0,
    accountName: '',
    phone: '',
    website: '',
    email: '',
    address: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    logoPath: '' // Optional, initialize as an empty string or undefined
});

const [error, setError] = useState<string | null>(null);
const [success, setSuccess] = useState<boolean>(false);


useEffect(() => {
  
  // Only fetch data if userIdentity.userId is available
  if (userIdentity?.userId) {
    const fetchProfile = async () => {
      try {
        const account_response = await getAccountByUserId(userIdentity.userId);
        setFormData(account_response.data); // Set formData with fetched profile data
      } catch (err) {
        setError("There was an error. Please try again.");
      }
    };
    fetchProfile();
  }
}, [userIdentity?.userId]);

  // Handle input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

// Handle form submission
const handleSubmit = async (e: React.FormEvent) => {
  e.preventDefault();
  setError(null); // Reset error before submission
  setSuccess(false); // Reset success message before submission

  try {
    const response = await upsertAccountInfo(formData);
    if (response.status === 200) {
      setSuccess(true); // Indicate successful submission
      console.log("Profile created successfully:", response.data);
    }
  } catch (error) {
    setError(error instanceof Error ? error.message : "An unexpected error occurred");
  }
};

 // Handle Discard button click
 const handleDiscard = () => {
  navigate('/account/details'); // Route to /details page
};

return (
  <div className="card mb-5 mb-xl-10">
    <div className="card-header border-0 cursor-pointer">
      <div className="card-title m-0">
        <h3 className="fw-bold m-0">Account Details</h3>
      </div>
    </div>

    <div id="kt_account_settings_profile_details" className="collapse show">
      <form id="kt_account_profile_details_form" className="form" onSubmit={handleSubmit}>
        <div className="card-body border-top p-9">
        {error && <div className="alert alert-danger">{error}</div>}
        {success && <div className="alert alert-success">Update saved successfully!</div>}

          {/* Company Name */}
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Company</label>
            <div className="col-lg-8 fv-row">
              <input
                type="text"
                name="accountName"
                className="form-control form-control-lg form-control-solid"
                placeholder="Company name"
                value={formData.accountName}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Contact Phone */}
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">Contact Phone</label>
            <div className="col-lg-8 fv-row">
              <input
                type="tel"
                name="phone"
                className="form-control form-control-lg form-control-solid"
                placeholder="Phone number"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Company Website */}
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">Company Site</label>
            <div className="col-lg-8 fv-row">
              <input
                type="text"
                name="website"
                className="form-control form-control-lg form-control-solid"
                placeholder="Company website"
                value={formData.website}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Email Website */}
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">Email</label>
            <div className="col-lg-8 fv-row">
              <input
                type="text"
                name="email"
                className="form-control form-control-lg form-control-solid"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* address */}
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">Street Address</label>
            <div className="col-lg-8 fv-row">
              <input
                type="text"
                name="address"
                className="form-control form-control-lg form-control-solid"
                placeholder="Steet Address"
                value={formData.address}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* City Website */}
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">City</label>
            <div className="col-lg-8 fv-row">
              <input
                type="text"
                name="city"
                className="form-control form-control-lg form-control-solid"
                placeholder="City Name"
                value={formData.city}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* State */}
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">State</label>
            <div className="col-lg-8 fv-row">
              <input
                type="text"
                name="state"
                className="form-control form-control-lg form-control-solid"
                placeholder="State"
                value={formData.state}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Postal Code */}
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">Postal Code</label>
            <div className="col-lg-8 fv-row">
              <input
                type="text"
                name="postalCode"
                className="form-control form-control-lg form-control-solid"
                placeholder="Postal Code"
                value={formData.postalCode}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Country */}
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">Country</label>
            <div className="col-lg-8 fv-row">
              <input
                type="text"
                name="country"
                className="form-control form-control-lg form-control-solid"
                placeholder="Country"
                value={formData.country}
                onChange={handleChange}
              />
            </div>
          </div>                                                     

        </div>

        {/* Form Actions */}
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button type="reset" className="btn btn-light btn-active-light-primary me-2" onClick={handleDiscard}>
            Discard
          </button>
          <button type="submit" className="btn btn-primary">
            Save Changes
          </button>
        </div>
      </form>
    </div>
  </div>
);
};

export default AccountDetailsEdit;
