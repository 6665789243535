import React, { useEffect, useState, useRef } from "react";
import QuoteHeader from "./ViewQuote/QuoteHeader";
import QuoteDetails from "./ViewQuote/QuoteDetails";
import PaymentDetails from "./ViewQuote/PaymentDetails";
import QuoteItemsTable from "./ViewQuote/QuoteItemsTable";
import QuoteSummary from "./ViewQuote/QuoteSummary";
import { getQuoteWithItemsById, QuoteWithItems } from "../../../api/quoteApi";
import { useParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const QuoteView: React.FC = () => {
  const { quoteID } = useParams();
  const [quoteData, setQuoteData] = useState<QuoteWithItems | null>(null);
  const [loadingData, setLoadingData] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const quoteRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let isMounted = true; // To track component's mounted state
    const fetchQuoteData = async () => {
      if (!quoteID) {
        setError("Quote ID is missing.");
        setLoadingData(false);
        return;
      }

      try {
        const quoteWithItems = await getQuoteWithItemsById(Number(quoteID));
        if (isMounted) {
          setQuoteData(quoteWithItems);
        }
      } catch (error) {
        console.error("Error fetching quote:", error);
        if (isMounted) {
          setError("Failed to fetch quote details. Please try again later.");
        }
      } finally {
        if (isMounted) setLoadingData(false);
      }
    };

    fetchQuoteData();

    return () => {
      isMounted = false; // Cleanup function
    };
  }, [quoteID]);

  const downloadPDF = async () => {
    if (quoteRef.current) {
      try {
        setLoadingPDF(true);
  
        const pdfWidth = 595.28; 
        const pdfHeight = 841.89; 
        const scale = 2; // You can adjust this if needed
  
        const clonedElement = quoteRef.current.cloneNode(true) as HTMLElement;
  
        // Remove the width setting here to let the content flow naturally
        clonedElement.style.maxWidth = "none"; 
        clonedElement.style.padding = "20px"; 
        clonedElement.style.position = "absolute";
        clonedElement.style.left = "-9999px"; 
        document.body.appendChild(clonedElement);
  
        const canvas = await html2canvas(clonedElement, {
          scale,
          useCORS: true,
        });
  
        document.body.removeChild(clonedElement); 
  
        const imgData = canvas.toDataURL("image/png");
  
        const pdf = new jsPDF({
          unit: "pt",
          format: "a4",
          orientation: "portrait", 
          compress: true // Enable compression 
        });
  
        // Calculate dimensions while maintaining aspect ratio, but based on canvas width
        const imgWidth = pdfWidth;  
        const imgHeight = (canvas.height / canvas.width) * imgWidth;
  
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight); 
  
        pdf.save(`Quote${quote.quoteId}.pdf`); 
      } catch (error) {
        console.error("Error generating PDF:", error);
        alert("Failed to generate PDF. Please try again.");
      } finally {
        setLoadingPDF(false);
      }
    }
  };

  
  

  if (loadingData) {
    return <div>Loading quote details...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!quoteData) {
    return <div>No quote data available.</div>;
  }

  const { quote, quoteItems } = quoteData;

  return (
    <div id="kt_app_content_container" className="app-container container-xxl">
      <button onClick={downloadPDF} disabled={loadingPDF}>
        {loadingPDF ? "Generating PDF..." : "Download PDF"}
      </button>
      <div className="card" ref={quoteRef}>
        <div className="card-body p-lg-20">
          <div className="d-flex flex-column flex-xl-row">
            <div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">
              <div className="mt-n1">
                <QuoteHeader />
                <div className="m-0">
                  <QuoteDetails quote={quote} />
                  <div className="flex-grow-1">
                    <QuoteItemsTable quoteItems={quoteItems} />
                    <QuoteSummary quote={quote} />
                  </div>
                </div>
              </div>
            </div>
            <PaymentDetails />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteView;
