import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { postCustomer, Customer } from '../../../api/customersAPI';

interface AddCustomerModalProps {
  isOpen: boolean;
  accountID: number;
  onClose: () => void;
}

const AddCustomerModal: React.FC<AddCustomerModalProps> = ({ isOpen, accountID, onClose }) => {
  const [customer, setCustomer] = useState<Customer>({
    customerId: 0, // Default ID; will typically be ignored by the server
    firstName: '',
    lastName: '',
    email: '',
    mobilePhone: '',
    homePhone: '',
    workPhone: '',
    accountId:accountID
  });

  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  if (!isOpen) return null;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCustomer({ ...customer, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      await postCustomer(customer);
      onClose(); // Close modal on success
    } catch (err) {
      setError((err as Error).message || 'Failed to add customer');
    } finally {
      setIsSubmitting(false);
    }
  };

  return ReactDOM.createPortal(
    <>
      {/* Backdrop */}
      <div
        className="modal-backdrop fade show"
        style={{
          zIndex: 1040,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      ></div>

      {/* Modal */}
      <div
        className="modal fade show"
        style={{
          display: 'block',
          zIndex: 1050,
        }}
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bold">Add a Customer</h2>
              <button
                type="button"
                className="btn btn-icon btn-sm btn-active-icon-primary"
                onClick={onClose}
              >
                <i className="ki-duotone ki-cross fs-1">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-7">
                  <label className="required fs-6 fw-semibold mb-2">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={customer.firstName}
                    onChange={handleInputChange}
                    className="form-control form-control-solid"
                    placeholder="First Name"
                    required
                  />
                </div>
                <div className="mb-7">
                  <label className="required fs-6 fw-semibold mb-2">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={customer.lastName}
                    onChange={handleInputChange}
                    className="form-control form-control-solid"
                    placeholder="Last Name"
                    required
                  />
                </div>
                <div className="mb-7">
                  <label className="required fs-6 fw-semibold mb-2">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={customer.email}
                    onChange={handleInputChange}
                    className="form-control form-control-solid"
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="mb-7">
                  <label className="fs-6 fw-semibold mb-2">Mobile Phone</label>
                  <input
                    type="text"
                    name="mobilePhone"
                    value={customer.mobilePhone}
                    onChange={handleInputChange}
                    className="form-control form-control-solid"
                    placeholder="Mobile Phone"
                  />
                </div>
                <div className="mb-7">
                  <label className="fs-6 fw-semibold mb-2">Home Phone</label>
                  <input
                    type="text"
                    name="homePhone"
                    value={customer.homePhone}
                    onChange={handleInputChange}
                    className="form-control form-control-solid"
                    placeholder="Home Phone"
                  />
                </div>
                <div className="mb-7">
                  <label className="fs-6 fw-semibold mb-2">Work Phone</label>
                  <input
                    type="text"
                    name="workPhone"
                    value={customer.workPhone}
                    onChange={handleInputChange}
                    className="form-control form-control-solid"
                    placeholder="Work Phone"
                  />
                </div>
                {error && <div className="text-danger mb-3">{error}</div>}
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-light me-3"
                    onClick={onClose}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.body
  );
};

export default AddCustomerModal;