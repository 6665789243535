import React from "react";

import { Quote } from "../../../../api/quoteApi";


interface QuoteSummaryProps {
  quote: Quote;
}

const QuoteSummary = ({ quote }: QuoteSummaryProps) => (
    <div className="d-flex justify-content-end">
      <div className="mw-300px">
        <div className="d-flex flex-stack mb-3">
          <div className="fw-semibold pe-10 text-gray-600 fs-7">Subtotal:</div>
          <div className="text-end fw-bold fs-6 text-gray-800">$ ${quote.totalAmount.toFixed(2)}</div>
        </div>
        <div className="d-flex flex-stack mb-3">
          <div className="fw-semibold pe-10 text-gray-600 fs-7">VAT 0%</div>
          <div className="text-end fw-bold fs-6 text-gray-800">0.00</div>
        </div>
        <div className="d-flex flex-stack mb-3">
          <div className="fw-semibold pe-10 text-gray-600 fs-7">
            Subtotal + VAT
          </div>
          <div className="text-end fw-bold fs-6 text-gray-800">$ ${quote.totalAmount.toFixed(2)}</div>
        </div>
        <div className="d-flex flex-stack">
          <div className="fw-semibold pe-10 text-gray-600 fs-7">Total</div>
          <div className="text-end fw-bold fs-6 text-gray-800">$ ${quote.totalAmount.toFixed(2)}</div>
        </div>
      </div>
    </div>
  );

  export default QuoteSummary;