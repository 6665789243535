import axios from 'axios';

// Types for RegisterUserDto
export interface RegisterUserDto {
    email: string;
    firstName: string;
    lastName: string;
    role: string; // e.g., "admin" or "member"
    firebaseUid: string;
}

// API to register a new user
export const registerUser = async (registerUserPayload: RegisterUserDto) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}/api/Registration`;
        const response = await axios.post(url, registerUserPayload);
        return response; // Return the response from the server
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || 'Failed to register user');
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
