import React from 'react';
import { AppRoutes } from './Components/routing/AppRoutes';
import { AuthProvider } from './Components/auth/authProvider';
import { UserProvider } from './Components/context/UserContext';
import { CustomerSearchProvider } from './Components/context/CustomerSearchContext';
import { AppProvider } from './Components/context/AppContext';
import { LoadingProvider } from './Components/context/LoadingContext';
import GlobalLoader from './Components/shared/GlobalLoader';

const App: React.FC = () => {
  return (
    <LoadingProvider>
      <GlobalLoader />
      <AuthProvider>
        <AppProvider>
          <UserProvider>
            <CustomerSearchProvider>
              <AppRoutes />
            </CustomerSearchProvider>
          </UserProvider>
        </AppProvider>
      </AuthProvider>
    </LoadingProvider>
  );
};

export default App;
