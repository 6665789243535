import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomerDetailsSidebar from './CustomerDetailsSidebar';
import CustomerDetailsTab from './CustomerDetailsTabs';
import CustomerQuoteRecords from './CustomerQuoteRecords';

const CustomerDetailsWrapper: React.FC = () => {
    const { customerId } = useParams<{ customerId: string }>();
    const [activeTab, setActiveTab] = useState<string>('overview');
  
    const handleTabChange = (tab: string) => {
      setActiveTab(tab);
    };  

    console.log('Customer ID from URL:', customerId);

  return (
    // <!--begin::Content container-->
    <div id="kt_app_content_container" className="app-container container-xxl">
        
        {/* <!--begin::Layout--> */}
        <div className="d-flex flex-column flex-xl-row">
            {/* <!--begin::Sidebar--> */}
            <CustomerDetailsSidebar customerId={Number(customerId)} />

            {/* <!--begin::Content--> */}
            <div className="flex-lg-row-fluid ms-lg-15">

            {/* <!--begin:::Tabs--> */}
            <CustomerDetailsTab
                onTabChange={handleTabChange} // Pass the handler to change tabs
                activeTab={activeTab} // Pass the current active tab
            />

            {/* <!--begin:::Tab content--> */}
            <div className="tab-content" id="myTabContent">
                {activeTab === 'overview' && <CustomerQuoteRecords customerId={Number(customerId)}/>}
                {/* {activeTab === 'events_logs' && <div>Events & Logs Content</div>}
                {activeTab === 'statements' && <div>Statements Content</div>} */}

            </div>
            {/* <!--end:::Tab content--> */}

            </div>
            {/* <!--end::Content--> */}

        </div>


    </div>
  );
};

export default CustomerDetailsWrapper;
