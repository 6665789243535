import React from "react";

// New component for Address Information
const AddressInformation = ({ companyInfo, customerData, customerAddresses }: any) => {

    return (
      <div className="row gx-10 mb-5">
        {/* Company Address */}
        <div className="col-lg-6 mb-5">
          <label className="form-label fs-4 fw-bold text-gray-700 mb-2">
            Quote Issued From
          </label>
          <div className="fw-bold fs-5 text-gray-800">
            {companyInfo?.companyName}
          </div>
          <div className="fw-semibold fs-5 text-gray-600">
            {companyInfo?.address}
          </div>
          <div className="fw-semibold fs-5 text-gray-600">
            {companyInfo?.city}, {companyInfo?.state}{" "}
            {companyInfo?.postalCode}
          </div>
          <div className="fw-semibold fs-5 text-gray-600">
            {companyInfo?.phone}
          </div>
        </div>
  
        {/* Customer Address */}
        <div className="col-lg-6">
          <label className="form-label fs-6 fw-bold text-gray-700 mb-3">Quote Issued To</label>
          
          <div className="mb-5">
            <input type="text" className="form-control form-control-solid" placeholder="Name" value={customerData?.firstName}/>
          </div>

          <div className="mb-5">
            <input type="text" className="form-control form-control-solid" placeholder="Name" value={customerData?.lastName}/>
          </div>

          <div className="mb-5">
          <input type="text" className="form-control form-control-solid" placeholder="Deal Name"/>
          </div>

          </div>

              {/* <div className="col-lg-6 mb-5">
                <label className="form-label fs-4 fw-bold text-gray-700 mb-2">
                  Quote Issued To
                </label>
                <div className="fw-bold fs-5 text-gray-800">
                  {customerData?.firstName} {customerData?.lastName}
                </div>
                <div className="fw-semibold fs-5 text-gray-600">
                  {customerAddresses[0]?.addressLine1}
                </div>
                <div className="fw-semibold fs-5 text-gray-600">
                  {customerAddresses[0]?.city}, {customerAddresses[0]?.state}{" "}
                  {customerAddresses[0]?.postalCode}
                </div>
                <div className="fw-semibold fs-5 text-gray-600">
                  {customerData?.mobilePhone}
                </div>
              </div> */}
      </div>
    );
  };

  export default AddressInformation;