import React, { useRef, useEffect } from "react";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css"; // Import Flatpickr styles

interface DateInputProps {
  label?: string; // Optional label for the input
  name: string; // Name for the input field
  value: string; // Current value of the date
  onChange: (date: string) => void; // Callback to handle date change
  placeholder?: string; // Optional placeholder
  className?: string; // Optional additional CSS classes
}

const DateInput: React.FC<DateInputProps> = ({
  label,
  name,
  value,
  onChange,
  placeholder = "Select date",
  className = "form-control form-control-transparent fw-bold pe-5",
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  // Utility to format a Date object as yyyy-MM-dd
  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (inputRef.current) {
      const fp = flatpickr(inputRef.current, {
        defaultDate: value, // Pre-populate with the current value
        onChange: (selectedDates) => {
          // Callback when the date changes
          const formattedDate = selectedDates[0] ? formatDate(selectedDates[0]) : "";
          onChange(formattedDate);
        },
        dateFormat: "Y-m-d", // Flatpickr format for the input
      });

      return () => {
        fp.destroy(); // Clean up Flatpickr instance on unmount
      };
    }
  }, [value, onChange]);

  const handleIconClick = () => {
    if (inputRef.current) {
      inputRef.current.focus(); // Focus input to trigger Flatpickr
    }
  };

  return (
    <>
      <input
        ref={inputRef}
        type="text" // Flatpickr uses a text input
        className={className}
        name={name}
        placeholder={placeholder}
        readOnly // Prevent manual typing
      />
      <i
        className="ki-duotone ki-down fs-4 position-absolute ms-4 end-0"
        onClick={handleIconClick}
        style={{ cursor: "pointer" }}
      ></i>
    </>
  );
};

export default DateInput;
