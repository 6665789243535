import axios from 'axios';

// Types for AccountInfo
export interface AccountInfo {
    accountId: number; // Maps to "accountId" in the API response
    accountName: string; // Maps to "accountName" in the API response
    phone: string; // Maps to "phone" in the API response
    website: string; // Maps to "website" in the API response
    email: string; // Maps to "email" in the API response
    address: string; // Maps to "address" in the API response
    city: string; // Maps to "city" in the API response
    state: string; // Maps to "state" in the API response
    postalCode: string; // Maps to "postalCode" in the API response
    country: string; // Maps to "country" in the API response
    logoPath?: string; // Maps to "logoPath" in the API response (optional)
}

// API to upsert AccountInfo
export const upsertAccountInfo = async (accountPayload: AccountInfo) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}/api/Account`;
        const response = await axios.post(url, accountPayload);
        return response; // Return the saved account object
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || 'Failed to save account information');
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

// API to get AccountInfo by UserId
export const getAccountByUserId = async (userId: number) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}/api/Account/user/${userId}`;
        const response = await axios.get(url);
        return response; // Return the account object
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || `Failed to fetch account information for userId: ${userId}`);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

// API to get AccountInfo by accountId
export const getAccountInfoById = async (accountId: number) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}/api/Account/${accountId}`;
        const response = await axios.get(url);
        return response.data; // Return the CompanyInfo object
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || `Failed to fetch account information for accountId: ${accountId}`);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

// API to delete AccountInfo by CompanyId
export const deleteAccountById = async (accountId: number) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}/api/Account/${accountId}`;
        const response = await axios.delete(url);
        return response.data; // Return the response from the server
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || `Failed to delete Account information with accountId: ${accountId}`);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};
