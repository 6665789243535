import React, { useState } from 'react';
import AddCustomerModal from './AddCustomerModal';
import { useUser } from '../../../Components/context/UserContext';

const NoCustomers: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const { userIdentity } = useUser();
  const handleModalToggle = () => setShowModal((prev) => !prev);

  return (
    <div id="kt_app_content_container" className="app-container container-xxl">
      {/* Card */}
      <div className="card">
        <div className="card-body p-0">
          {/* Wrapper */}
          <div className="card-px text-center py-20 my-10">
            <h2 className="fs-2x fw-bold mb-10">Welcome to Customers App</h2>
            <p className="text-gray-500 fs-4 fw-semibold mb-10">
              There are no customers added yet.
              <br />
              Kickstart your CRM by adding your first customer.
            </p>
            <button className="btn btn-primary" onClick={handleModalToggle}>
              Add Customer
            </button>
          </div>
          {/* Illustration */}
          <div className="text-center px-4">
            <img
              className="mw-100 mh-300px"
              alt="Illustration"
              src="/assets/media/illustrations/sketchy-1/2.png"
            />
          </div>
        </div>
      </div>

      {/* Reusable Modal Component */}
      <AddCustomerModal isOpen={showModal} accountID={userIdentity?.accountId || 0} onClose={handleModalToggle} />
    </div>
  );
};

export default NoCustomers;
