import axios from 'axios';

export interface Quote {
    quoteId: number;
    accountId: number;
    userId: number;
    customerId: number;
    quoteDate: string;
    expirationDate: string;
    dueDate: string;
    totalAmount: number;
    statusID: number;
    statusName: string;
    dealName: string;
}

export interface QuoteItem {
    quoteItemID: number;
    quoteID: number;
    productID: number;
    itemName: string;
    itemDescription: string;
    quantity: number;
    price: number;
}

export interface QuoteWithItems {
    quote: Quote;
    quoteItems: QuoteItem[];
}

export const badgeQuoteClassMap: Record<number, string> = {
    0: "badge-light-secondary", // For statusID 0: draft
    20: "badge-light-primary", // For statusID 20: sent
    30: "badge-light-success", // For statusID 30: accepted
    40: "badge-light-danger",  // For statusID 40: rejected
    90: "badge-light-warning",  // For statusID 40: expired
  };

export const postQuoteWithItems = async (quotePayload: QuoteWithItems) => {
    try {
        console.log("QuoteWithItems", quotePayload)
        const url = `${process.env.REACT_APP_API_URL}/api/Quote/quote-with-items`;
        const response = await axios.post(url, quotePayload);
        console.log("response", response)
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || 'Failed to create quote');
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

// Function to fetch a quote by customer ID
export const getQuotesCustomerById = async (customerID: number) => {
    try {
        console.log(`[getCustomerById] Fetching quotes for customerID: ${customerID}`);
        const url = `${process.env.REACT_APP_API_URL}/api/Quote/customer/${customerID}`;
        const response = await axios.get(url);
        return response.data; // Return the response data directly
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || 'Failed to fetch quotes for user');
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

// Function to fetch a quote by its ID
export const getQuoteWithItemsById = async (quoteID: number): Promise<QuoteWithItems> => {
    try {
        console.log(`[getQuoteById] Fetching quote for quoteID: ${quoteID}`);
        const url = `${process.env.REACT_APP_API_URL}/api/Quote/quote-with-items/${quoteID}`;
        const response = await axios.get<QuoteWithItems>(url); // Expect a QuoteWithItems response
        return response.data; // Return the full quote object with items
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || 'Failed to fetch quote by ID');
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

// Function to fetch a quote by customer ID
export const getQuotesForUserId = async (userId: number) => {
    try {
        console.log(`[getCustomerById] Fetching quotes for userID: ${userId}`);
        const url = `${process.env.REACT_APP_API_URL}/api/Quote/user/${userId}`;
        const response = await axios.get(url);
        return response.data; // Return the response data directly
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || 'Failed to fetch quotes for user');
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

// Function to fetch a quote by account ID
export const getQuotesForAccountId = async (accountId: number) => {
    try {
        console.log(`[getQuotesForAccountId] Fetching quotes for accountId: ${accountId}`);
        const url = `${process.env.REACT_APP_API_URL}/api/Quote/account/${accountId}`;
        const response = await axios.get(url);
        return response.data; // Return the response data directly
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || 'Failed to fetch quotes for account');
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};