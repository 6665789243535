import React from "react";
import { Quote } from "../../../api/quoteApi";

interface QuoteInformationProps {
  quote: Quote;
  setQuote: React.Dispatch<React.SetStateAction<Quote>>;
}

// New component for Address Information
const QuoteInformation: React.FC<QuoteInformationProps> = ({ quote, setQuote }) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setQuote(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

    return (
      <div className="row gx-10 mb-5">
      <label className="form-label fs-6 fw-bold text-gray-700 mb-4">Quote Details</label>
        {/* Company Address */}
        <div className="col-lg-6">

          
          <div className="mb-5">
            <input type="text" className="form-control form-control-solid" placeholder="Quote Owner"/>
          </div>

          </div>
  
        {/* Deal Name */}
        <div className="col-lg-6">
          
            <div className="mb-5">
            <input
              type="text"
              className="form-control form-control-solid"
              placeholder="Deal Name"
              name="dealName"
              value={quote.dealName}
              onChange={handleInputChange}
            />
            </div>

          </div>

      </div>
    );
  };

  export default QuoteInformation;