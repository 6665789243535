import React from 'react';
import { useLoading } from '../context/LoadingContext';
import Spinner from './Spinner'; // Adjust the path as needed

const GlobalLoader: React.FC = () => {
  const { isLoading } = useLoading();

  return (
    isLoading ? (
      <div className="overlay overlay-block fixed-top w-100 h-100 d-flex align-items-center justify-content-center bg-dark bg-opacity-25">
        <Spinner />
      </div>
    ) : null
  );
};

export default GlobalLoader;
