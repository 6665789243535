import React from "react";
import { Link, useMatch, useLocation } from "react-router-dom";

export const ToolBar: React.FC = () => {
  const location = useLocation();
  const matchAccount = useMatch("/account/*");
  const matchDashboard = useMatch("/dashboards");
  const matchCustomerList = useMatch("/customers/list");
  const matchCustomerDetails = useMatch("/customers/:customer_id");
  const matchQuoteList = useMatch("/quotes/list");
  const matchQuoteEdit = useMatch("/quotes/edit/:quoteID");
  const matchQuoteView = useMatch("/quotes/view/:quoteID"); 
  const matchAllQuotes = useMatch("/quotes");

  const getPageDetails = () => {
    if (matchAccount) {
      return {
        title: "Account Info",
        breadcrumbs: ["Home", "Account"],
        actions: [],
      };
    }

    if (matchCustomerList) {
      return {
        title: "Customer List",
        breadcrumbs: ["Home", "Customers"],
        actions: [],
      };
    }

    if (matchCustomerDetails) {
      return {
        title: "Customer Details",
        breadcrumbs: [
          "Home",
          { label: "Customers", to: "/customers/list" },
          "Details",
        ],
        actions: [],
      };
    }

    if (matchDashboard) {
      return {
        title: "Dashboards",
        breadcrumbs: ["Home", "Dashboards"],
        actions: [
          {
            label: "Add Customer",
            to: "/apps/customers/list",
            className: "btn-secondary",
          },
          {
            label: "New Shipment",
            to: "/apps/ecommerce/sales/add-order",
            className: "btn-primary",
          },
        ],
      };
    }

    if (matchQuoteList) {
      return {
        title: "Quote List",
        breadcrumbs: ["Home", "Quotes"],
        actions: [],
      };
    }

    if (matchQuoteEdit) {
      return {
        title: "Edit Quote",
        breadcrumbs: [
          "Home",
          { label: "Quotes", to: "/quotes" }, 
          "Edit",
        ],
        actions: [
          {
            label: "View Quote",
            to: `/quotes/view/${location.pathname.split('/').pop()}`, // Extract quoteID from URL
            className: "btn-primary", 
          },
        ],
      };
    }

    if (matchQuoteView) {
      return {
        title: "View Quote",
        breadcrumbs: [
          "Home",
          { label: "Quotes", to: "/quotes" }, 
          "View",
        ],
        actions: [
          {
            label: "Edit Quote",
            to: `/quotes/edit/${location.pathname.split('/').pop()}`, // Extract quoteID from URL
            className: "btn-primary", 
          },
        ],
      };
    }
    
    if (matchAllQuotes) {
      return {
        title: "All Quotes", // Or any title you prefer
        breadcrumbs: ["Home", "Quotes"],
        actions: [
          {
            label: "New Quote",
            to: "/quotes/new", // Or your route for creating a new quote
            className: "btn-primary",
          },
        ],
      };
    }    

    switch (location.pathname) {
      case "/":
        return {
          title: "Home",
          breadcrumbs: ["Home"],
          actions: [],
        };
      default:
        return {
          title: "Page Not Found",
          breadcrumbs: ["Home", "404"],
          actions: [],
        };
    }
  };

  const { title, breadcrumbs, actions } = getPageDetails();

  return (
    <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
      {/* Toolbar container */}
      <div
        id="kt_app_toolbar_container"
        className="app-container container-xxl d-flex flex-stack"
      >
        {/* Page title */}
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          {/* Title */}
          <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
            {title}
          </h1>
          {/* Breadcrumb */}
          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            {breadcrumbs.map((crumb, index) => (
              <li
                key={index}
                className={`breadcrumb-item ${
                  index === breadcrumbs.length - 1 ? "text-muted" : ""
                }`}
              >
                {typeof crumb === "string" ? (
                  index === breadcrumbs.length - 1 ? (
                    crumb
                  ) : (
                    <Link to="/" className="text-muted text-hover-primary">
                      {crumb}
                    </Link>
                  )
                ) : (
                  <Link
                    to={crumb.to || "#"}
                    className="text-muted text-hover-primary"
                  >
                    {crumb.label}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
        {/* Actions */}
        <div className="d-flex align-items-center gap-2 gap-lg-3">
          {actions.map((action, index) => (
            <Link
              key={index}
              to={action.to}
              className={`btn btn-sm fw-bold ${action.className}`}
            >
              {action.label}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ToolBar;
