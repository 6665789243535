import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

// Create an instance of Axios Mock Adapter
// const mock = new MockAdapter(axios);

// // Mock the /profile API response
// mock.onPost('/profile').reply(200, {
//     message: 'Profile created successfully'
// });

export interface UserIdentityPayload {
    userId: number;
    accountId: number;
    email: string;
    firebaseUserID: string
    // Add any other profile data you need here
}

export const createUserIdentity = async (useridentityPayload: UserIdentityPayload) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}/api/userIdentity`;
        const response = await axios.post(url, useridentityPayload);
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || 'Failed to create profile');
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

export const getUserIdentityByFirebaseId = async (id: string) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}/api/userIdentity/firebase/${id}`;
        const response = await axios.get(url);
        return response.data; // Return the response data directly
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || 'Failed to fetch user identity');
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};