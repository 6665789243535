import React from "react";
import { QuoteItem } from "../../../../api/quoteApi";

interface QuoteItemsTableProps {
  quoteItems: QuoteItem[];
}

const QuoteItemsTable = ({ quoteItems }: QuoteItemsTableProps) => (
    <div className="table-responsive border-bottom mb-9">
      <table className="table mb-3">
        <thead>
          <tr className="border-bottom fs-6 fw-bold text-muted">
            <th className="min-w-175px pb-2">Description</th>
            <th className="min-w-70px text-end pb-2">Hours</th>
            <th className="min-w-80px text-end pb-2">Rate</th>
            <th className="min-w-100px text-end pb-2">Amount</th>
          </tr>
        </thead>
        <tbody>
        {quoteItems.map((item) => (
          <tr key={item.quoteItemID} className="fw-bold text-gray-700 fs-5 text-end">
            <td className="d-flex align-items-center pt-6">
              <i className="fa fa-genderless text-danger fs-2 me-2"></i>
              {item.itemName}
            </td>
            <td className="pt-6">{item.quantity}</td>
            <td className="pt-6">${item.price.toFixed(2)}</td>
            <td className="pt-6 text-gray-900 fw-bolder">
              ${(item.quantity * item.price).toFixed(2)}
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
);

export default QuoteItemsTable;