import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";

export const AccountNavs = () => {
    return (
        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
        
        {/* Nav item */}
        <li className="nav-item mt-2">
          <NavLink
            to="/account/details"
            className={({ isActive }) => 
              `nav-link text-active-primary ms-0 me-10 py-5 ${isActive ? "active" : ""}`
            }
          >
            Details
          </NavLink>
        </li>
        {/* End Nav item */}
        
        
      </ul>

    );
};        