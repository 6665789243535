import React, { useEffect, useState } from "react";
import { Quote, badgeQuoteClassMap, getQuotesCustomerById } from "../../../../api/quoteApi";
import { useNavigate } from "react-router-dom";

interface QuoteSummaryTableProps {
  customerID: number; // Pass userId as a prop
}

const QuoteSummaryTable: React.FC<QuoteSummaryTableProps> = ({ customerID }) => {
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleQuoteClick = (quoteId: number, event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault(); // Prevent the default anchor behavior
    navigate(`/quotes/edit/${quoteId}`);
  };

  // Fetch quotes based on userId
  useEffect(() => {
    const fetchQuotes = async () => {
      setLoading(true);
      try {
        const data = await getQuotesCustomerById(customerID); // Fetch data
        setQuotes(data); // Update state
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "Failed to fetch quotes."
        );
      } finally {
        setLoading(false);
      }
    };

    if (customerID) {
      fetchQuotes();
    }
  }, [customerID]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="table-responsive">
        <table className="table align-middle table-row-dashed gy-5" id="quote_summary_table">
        <thead className="border-bottom border-gray-200 fs-7 fw-bold">
            <tr className="text-start text-muted text-uppercase gs-0">
            <th className="min-w-100px">Quote ID</th>
            <th>Status</th>
            <th>Total Amount</th>
            <th className="min-w-100px">Quote Date</th>
            <th className="text-end min-w-100px pe-4">Actions</th>
            </tr>
        </thead>
        <tbody className="fs-6 fw-semibold text-gray-600">
            {quotes.map((quote) => {

            // Define badge class dynamically for each quote
            const badgeClass = badgeQuoteClassMap[quote.statusID] || "badge-light-secondary";
            
            return (
            <tr key={quote.quoteId}>
                {/* Quote ID */}
                <td>
                  <a href={`/quotes/edit/${quote.quoteId}`} // Provides a fallback in case JavaScript is disabled
                    onClick={(event) => handleQuoteClick(quote.quoteId, event)}
                    className="text-gray-600 text-hover-primary mb-1"
                  >
                    {quote.quoteId}
                  </a>
                </td>

                {/* Quote Status */}
                <td>
                <span className={`badge ${badgeClass}`}>
                {quote.statusName}
                </span>
                </td>

                {/* Total Amount */}
                <td>${quote.totalAmount.toFixed(2)}</td>

                {/* Quote Date */}
                <td>{new Date(quote.quoteDate).toLocaleDateString()}</td>

                {/* Actions */}
                <td className="pe-0 text-end">
                <a
                    href="#"
                    className="btn btn-sm btn-light btn-active-light-primary"
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"
                >
                    Actions <i className="ki-duotone ki-down fs-5 ms-1"></i>
                </a>
                <div
                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
                    data-kt-menu="true"
                >
                    <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3">
                        View
                    </a>
                    </div>
                    <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3" data-kt-customer-table-filter="delete_row">
                        Delete
                    </a>
                    </div>
                </div>
                </td>
            </tr>
          );
        })}
        </tbody>
        </table>
    </div>
  );
};

export default QuoteSummaryTable;
