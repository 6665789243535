import React, { useContext } from "react";
import { AuthContext } from '../../Components/auth/authContext';
import { useUser } from "../../Components/context/UserContext";
import EngageWidget from "./components/EngageWidget";

const HomePage: React.FC = () => {
  const currentUser = useContext(AuthContext);
  const { userIdentity } = useUser();

  return (
    //<!--begin::Content-->
    <div id="kt_app_content" className="app-content flex-column-fluid">
      

      {/* <!--begin::Content container--> */}
      <div id="kt_app_content_container" className="app-container container-xxl">
          {/* <!--begin::Row--> */}
          <div className="row gy-5 gx-xl-10">

              {/* <!--begin::Col--> */}
              <div className="col-xl-4 mb-xl-10">
                <EngageWidget/>   

              </div>
              {/* <!--end::Col--> */}
                      
          </div>
        {/* <!--end::Row--> */}

      </div>
      {/* <!--end::Content container--> */}


      <h3>Current User (AuthContext)</h3>
      <pre>{JSON.stringify(currentUser, null, 2)}</pre>

      <h3>User Identity</h3>
      <pre>{JSON.stringify(userIdentity, null, 2)}</pre>

    </div>
  );
};

export { HomePage };
