import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import { AuthContext } from '../../Components/auth/authContext';
import AccountNavBar from "./components/navbar";
import AccountDetailsEdit from "./components/accountDetailsEdit";

const AccountPage: React.FC = () => {
  const currentUser = useContext(AuthContext);

  return (
    <div id="kt_app_content" className="app-content flex-column-fluid">
      {/* Begin Content Container */}
      <div id="kt_app_content_container" className="app-container container-xxl">
      <div className="card mb-5 mb-xl-10">
        {/* Begin NavBar */}
        <AccountNavBar/>

        {/* Nested Routes Content */}
        <Outlet />

        </div>
      </div>
    </div>
  );
};

export { AccountPage };
