import React from "react";
import { Quote } from "../../../../api/quoteApi";
import { formatDate } from "../../../../Components/Layout/utils/dateFormat";

interface QuoteDetailsProps {
  quote: Quote;
}

const QuoteDetails = ({ quote }: QuoteDetailsProps) => (
    <div>
      <div className="fw-bold fs-3 text-gray-800 mb-8">Quote #{quote.quoteId}</div>
      <div className="row g-5 mb-11">
        <div className="col-sm-6">
          <div className="fw-semibold fs-7 text-gray-600 mb-1">Quote Date:</div>
          <div className="fw-bold fs-6 text-gray-800">{formatDate(quote.quoteDate.toString())}</div>
        </div>
        <div className="col-sm-6">
          <div className="fw-semibold fs-7 text-gray-600 mb-1">Due Date:</div>
          <div className="fw-bold fs-6 text-gray-800 d-flex align-items-center flex-wrap">
            <span className="pe-2">02 May 2021</span>
            <span className="fs-7 text-danger d-flex align-items-center">
              <span className="bullet bullet-dot bg-danger me-2"></span>
              Due in 7 days
            </span>
          </div>
        </div>
      </div>
      <div className="row g-5 mb-12">
        <div className="col-sm-6">
          <div className="fw-semibold fs-7 text-gray-600 mb-1">Issue For:</div>
          <div className="fw-bold fs-6 text-gray-800">KeenThemes Inc.</div>
          <div className="fw-semibold fs-7 text-gray-600">
            8692 Wild Rose Drive <br />
            Livonia, MI 48150
          </div>
        </div>
        <div className="col-sm-6">
          <div className="fw-semibold fs-7 text-gray-600 mb-1">Issued By:</div>
          <div className="fw-bold fs-6 text-gray-800">CodeLab Inc.</div>
          <div className="fw-semibold fs-7 text-gray-600">
            9858 South 53rd Ave.
            <br />
            Matthews, NC 28104
          </div>
        </div>
      </div>
    </div>
  );


export default QuoteDetails;