import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../firebaseConfig';
import { getErrorMessage } from '../../../Components/auth/firebase/firebase_commons';
import { registerUser, RegisterUserDto } from '../../../api/registerApi';
import { getUserIdentityByFirebaseId } from '../../../api/userIdentityApi';
import { useUser } from '../../../Components/context/UserContext';

const RegisterForm: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [success, setSuccess] = useState<string>('');
    const [isChecked, setIsChecked] = useState(false);
    const { setUserIdentity } = useUser();

    const handleRegister = async (e: React.FormEvent) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        if (password.length < 8 || !/\d/.test(password) || !/[a-zA-Z]/.test(password) || !/[!@#$%^&*]/.test(password)) {
            setError('Password must be at least 8 characters long and include a mix of letters, numbers, and symbols.');
            return;
        }

        try {
             // Step 1: Register the user in Firebase
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const firebaseUserId  = userCredential.user.uid;
            console.log('User registered successfully with Firebase:', firebaseUserId);
            
            // Step 2: Register in the backend
            const registerUserPayload: RegisterUserDto = {
                email: email,
                firstName: '',
                lastName: '',
                role: 'admin',
                firebaseUid: firebaseUserId
            };

            console.log('Registering user in the backend:', registerUserPayload);

            const response = await registerUser(registerUserPayload);

            if (response.status === 200) {

                // Fetch the user identity by Firebase ID
                const userIdentity = await getUserIdentityByFirebaseId(firebaseUserId);
                setUserIdentity(userIdentity);  // Store the identity in context

                console.log('Registration successfully');
                setSuccess('User registered');
                setEmail('');
                setPassword('');
                setConfirmPassword('');
            } else {
                console.log('Full response:', response.data);
                console.error('Profile creation error:');
                setError('Failed to create profile');
                return;
            }

        } catch (err) {
            if (err instanceof Error) {
                const errorMessage = getErrorMessage((err as any).code);
                console.error('Registration error:', errorMessage);
                setError(errorMessage);
                return;
            } else {
                console.error('Unexpected error occurred during registration');
                setError('An unexpected error occurred');
                return;
            }
        }
    };


    return (
           // begin::Form
            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
            <div className="w-lg-500px p-10">
                {/*begin::Form*/}
                <form className="form w-100" noValidate id="kt_sign_up_form" onSubmit={handleRegister}>
                {/*begin::Heading*/}
                <div className="text-center mb-11">
                    {/*begin::Title*/}
                    <h1 className="text-gray-900 fw-bolder mb-3">Sign Up</h1>
                    {/*end::Title*/}
                    {/*begin::Subtitle*/}
                    <div className="text-gray-500 fw-semibold fs-6">Manage Your Home Services Business with Ease</div>
                    {/*end::Subtitle*/}
                </div>
                {/*end::Heading*/}
                {/*begin::Login options*/}
                <div className="row g-3 mb-9">
                    {/*begin::Col*/}
                    <div className="col-md-6">
                    {/*begin::Google link*/}
                    <a
                        href="#"
                        className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
                    >
                        <img
                        alt="Logo"
                        src="/assets/media/svg/brand-logos/google-icon.svg"
                        className="h-15px me-3"
                        />
                        Sign in with Google
                    </a>
                    {/*end::Google link*/}
                    </div>
                    {/*end::Col*/}
                    {/*begin::Col*/}
                    <div className="col-md-6">
                    {/*begin::Apple link*/}
                    <a
                        href="#"
                        className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
                    >
                        <img
                        alt="Logo"
                        src="/assets/media/svg/brand-logos/apple-black.svg"
                        className="theme-light-show h-15px me-3"
                        />
                        <img
                        alt="Logo"
                        src="/assets/media/svg/brand-logos/apple-black-dark.svg"
                        className="theme-dark-show h-15px me-3"
                        />
                        Sign in with Apple
                    </a>
                    {/*end::Apple link*/}
                    </div>
                    {/*end::Col*/}
                </div>
                {/*end::Login options*/}
                {/*begin::Separator*/}
                <div className="separator separator-content my-14">
                    <span className="w-125px text-gray-500 fw-semibold fs-7">Or with email</span>
                </div>
                {/*end::Separator*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-8">
                    {/*begin::Email*/}
                    <input
                    type="text"
                    placeholder="Email"
                    name="email"
                    autoComplete="off"
                    className="form-control bg-transparent"
                    value={email || ''}
                    onChange={(e) => setEmail(e.target.value)}                    
                    />
                    {/*end::Email*/}
                </div>
                {/*begin::Input group*/}
                <div className="fv-row mb-8" data-kt-password-meter="true">
                    {/*begin::Wrapper*/}
                    <div className="mb-1">
                    {/*begin::Input wrapper*/}
                    <div className="position-relative mb-3">
                        <input
                        className="form-control bg-transparent"
                        type="password"
                        placeholder="Password"
                        name="password"
                        autoComplete="off"
                        value={password || ''}
                        onChange={(e) => setPassword(e.target.value)}                           
                        />
                        <span
                        className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                        data-kt-password-meter-control="visibility"
                        >
                        <i className="ki-duotone ki-eye-slash fs-2"></i>
                        <i className="ki-duotone ki-eye fs-2 d-none"></i>
                        </span>
                    </div>
                    {/*end::Input wrapper*/}
                    {/*begin::Meter*/}
                    <div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
                        <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                    </div>
                    {/*end::Meter*/}
                    </div>
                    {/*end::Wrapper*/}
                    {/*begin::Hint*/}
                    <div className="text-muted">Use 8 or more characters with a mix of letters, numbers & symbols.</div>
                    {/*end::Hint*/}
                </div>
                {/*end::Input group*/}
                {/*begin::Input group*/}
                <div className="fv-row mb-8">
                    {/*begin::Repeat Password*/}
                    <input
                    placeholder="Repeat Password"
                    name="confirm-password"
                    type="password"
                    autoComplete="off"
                    className="form-control bg-transparent"
                    value={confirmPassword || ''}
                    onChange={(e) => setConfirmPassword(e.target.value)}                     
                    />
                    {/*end::Repeat Password*/}
                </div>
                {/*end::Input group*/}
                {/*begin::Accept*/}
                <div className="fv-row mb-8">
                    <label className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" name="toc"
                            checked={isChecked} 
                            onChange={(e) => setIsChecked(e.target.checked)}/>
                    <span className="form-check-label fw-semibold text-gray-700 fs-base ms-1">
                        I Accept the <a href="#" className="ms-1 link-primary">Terms</a>
                    </span>
                    </label>
                </div>
                {/*end::Accept*/}
                {/*begin::Submit button*/}
                <div className="d-grid mb-10">
                    <button type="submit" id="kt_sign_up_submit" className="btn btn-primary">
                    {/*begin::Indicator label*/}
                    <span className="indicator-label">Sign up</span>
                    {/*end::Indicator label*/}
                    {/*begin::Indicator progress*/}
                    <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                    {/*end::Indicator progress*/}
                    </button>
                </div>
                {/*end::Submit button*/}
                {/*begin::Sign up*/}
                <div className="text-gray-500 text-center fw-semibold fs-6">
                    Already have an Account? <a href="/login" className="link-primary fw-semibold">Sign in</a>
                </div>
                {/*end::Sign up*/}
                </form>
                {/*end::Form*/}
                {error && <div className="text-center text-danger mt-3">{error}</div>}
                {success && <div className="text-center text-success mt-3">{success}</div>}
            </div>
            </div>
            // end::Form


    );
};

export default RegisterForm;