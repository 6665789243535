import React from 'react';
import { useNavigate } from 'react-router-dom';
import QuoteSummaryTable from './tables/quoteSummaryTable';
import { useUser } from '../../../Components/context/UserContext';
import PaymentsMethods from './payments/PaymentMethods';

interface CustomerQuoteRecordsProps {
  customerId: number;
}

const CustomerQuoteRecords: React.FC<CustomerQuoteRecordsProps> = ({ customerId }) => {
  const navigate = useNavigate();

  const handleNewQuoteClick = () => {
    const route = customerId ? `/quotes/new/${customerId}` : `/quotes/new`;
    navigate(route);
  };

    const { userIdentity } = useUser();
    const userID = userIdentity?.userId || '';

  return (
    
    <div className="tab-pane fade show active" id="kt_customer_view_overview_tab" role="tabpanel">
      {/* Payment Records */}
      <div className="card pt-4 mb-6 mb-xl-9">
        <div className="card-header border-0">
          <div className="card-title">
            <h2>Quotes</h2>
          </div>
          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-sm btn-flex btn-light-primary"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_add_payment"
              onClick={handleNewQuoteClick}
            >
              <i className="ki-duotone ki-plus-square fs-3">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
              </i>
              New Quote
            </button>
          </div>
        </div>
        <div className="card-body pt-0 pb-5">
        <QuoteSummaryTable customerID={customerId} />

        </div>
      </div>

      {/* Payment Methods */}
      {/* <PaymentsMethods/> */}

    </div>
  );
};

export default CustomerQuoteRecords;
