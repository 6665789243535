import axios from 'axios';
import { Console } from 'console';

// Define the Customer type
export interface Customer {
    customerId: number;
    accountId: number;
    firstName: string;
    lastName: string;
    email: string;
    mobilePhone: string;
    homePhone: string;
    workPhone: string;
}

// Define the response structure for the paginated customers
export interface PaginatedCustomersResponse {
    customers: Customer[];
    currentPage: number;
    totalPages: number;
    totalItems: number;
}

// Function to create a new customer
export const postCustomer = async (customerPayload: Customer) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}/api/customers`;
        const response = await axios.post(url, customerPayload);
        return response.data; // Return the response data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || 'Failed to create customer');
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

// Function to fetch a customer by ID
export const getCustomerById = async (customerId: number) => {
    try {
        console.log(`[getCustomerById] Fetching customer with ID: ${customerId}`);
        const url = `${process.env.REACT_APP_API_URL}/api/customers/${customerId}`;
        const response = await axios.get(url);
        return response.data; // Return the response data directly
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || 'Failed to fetch customer');
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

// Function to fetch customers with pagination and optional search string
export const getCustomers = async (accountId: number, page: number, itemsPerPage: number, search?: string) => {
    try {
      console.log(`[getCustomers] Fetching customers for account ID: ${accountId}, page: ${page}, itemsPerPage: ${itemsPerPage}, search: ${search}`);
      const url = `${process.env.REACT_APP_API_URL}/api/Customers`;
      const params: Record<string, any> = {accountId, page, items_per_page: itemsPerPage };
  
      if (search) {
        params.search = search; // Add search to params if provided
      }
  
      const response = await axios.get(url, { params });
      if (Array.isArray(response.data)) {
        return { customers: response.data }; // Wrap plain array in object
      }
  
      return response.data; // Return as-is if wrapped in object
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data || 'Failed to fetch customers');
      } else {
        throw new Error('An unexpected error occurred');
      }
    }
  };

