import { getTodayDate } from "./dateHelpers";

export const isValidQuoteExpirationDate = (date: string): { valid: boolean; message: string | null } => {
    const selectedDate = new Date(date);
    const today = new Date(getTodayDate());
    today.setHours(0, 0, 0, 0);
  
    if (selectedDate < today) {
      return { valid: false, message: "Expiration date cannot be in the past." };
    }
    return { valid: true, message: null };
  };