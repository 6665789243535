import React from "react";

// InvoiceHeader Component
const QuoteHeader = () => (
  <div className="d-flex flex-stack pb-10">
    <a href="#">
      <img alt="Logo" src="assets/media/svg/brand-logos/code-lab.svg" />
    </a>
    <a href="#" className="btn btn-sm btn-success">
      Pay Now
    </a>
  </div>
);

export default QuoteHeader;