import React from 'react';
import { Link } from 'react-router-dom';


interface QuoteFormActionMenuProps {
    currency: string;
    setCurrency: (currency: string) => void;
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    quoteID?: number;
  }
const QuoteFormActionMenu: React.FC<QuoteFormActionMenuProps> = ({ currency, setCurrency, handleSubmit, quoteID }) => {
  const handleCurrencyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrency(event.target.value);
  };

  return (
<div className="flex-lg-auto min-w-lg-300px">
          <div
            className="card"
            data-kt-sticky="true"
            data-kt-sticky-name="invoice"
            data-kt-sticky-offset="{default: false, lg: '200px'}"
            data-kt-sticky-width="{lg: '250px', lg: '300px'}"
            data-kt-sticky-left="auto"
            data-kt-sticky-top="150px"
            data-kt-sticky-animation="false"
            data-kt-sticky-zindex="95"
          >
            <div className="card-body p-10">
              <div className="mb-0">
              <div className="row mb-5">
                  <div className="col">
                  <button
                  type="submit"
                  className="btn btn-light btn-active-light-primary w-100"
                  id="kt_invoice_submit_button"
                  onClick={(event) => {
                    event.preventDefault(); // Prevent the button's default behavior
                    handleSubmit(event as unknown as React.FormEvent<HTMLFormElement>); // Cast to match expected type
                  }}
                >
                  <i className="ki-duotone ki-triangle fs-3">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                  </i>
                  Save as Draft
                </button>

                  </div>
                </div>

                {/* <div className="row mb-5">
                  <div className="col">
                        {quoteID && Number(quoteID) !== 0 && (
                        <Link
                            to={`/quotes/view/${quoteID}`}
                            className="btn btn-light btn-active-light-primary w-100"
                        >
                            Preview
                        </Link>
                        )}
                  </div>
                </div> */}

                <div className="row mb-5">
                  <div className="col">

                  <Link
                            to={`/quotes/`}
                            className="btn btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger w-100"
                        >
                          Cancel
                        </Link>

 
                  </div>
                </div>                

              </div>
            </div>
          </div>
        </div>
  );
};

export default QuoteFormActionMenu;