import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Quote,
  badgeQuoteClassMap,
  getQuotesForAccountId,
  getQuotesForUserId,
} from "../../../api/quoteApi";
import QuoteGridHeader from "./QuoteGridHeader";
import { useUser } from "../../../Components/context/UserContext";

const QuotesGrid: React.FC = () => {
  const { userIdentity } = useUser();
  const userID = userIdentity?.userId || 0;
  const accountID = userIdentity?.accountId || 0;
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [searchString, setSearchString] = useState<string>("");
  const navigate = useNavigate();

  const fetchQuotes = async () => {
    setLoading(true);
    try {
      const data = await getQuotesForAccountId(accountID);
      setQuotes(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : "Failed to fetch quotes.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuotes();
  }, []);

  const handleSearchChange = (search: string): void => {
    setSearchString(search.toLowerCase());
  };

  const handleAddQuoteClick = (): void => {
    navigate("/quotes/add");
  };

  const handleQuoteClick = (
    quoteId: number,
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    navigate(`/quotes/edit/${quoteId}`);
  };

  const filteredQuotes = quotes.filter(
    (quote) =>
      quote.quoteId.toString().includes(searchString)
  );

  if (loading) return <div>Loading...</div>;
  if (error)
    return (
      <div className="alert alert-danger" role="alert">
        Error: {error}
      </div>
    );

  return (
    <div id="kt_app_content_container" className="app-container container-xxl">
      <div className="card">
        {/* Quotes Header */}
        <QuoteGridHeader
          onAddQuoteClick={handleAddQuoteClick}
          onSearchChange={handleSearchChange}
        />

        {/* Card Body */}
        <div className="card-body pt-0">
          <div className="table-responsive">
            <table className="table align-middle table-row-dashed fs-6 gy-5">
              <thead>
                <tr>
                  <th>Quote #</th>
                  <th>Client Name</th>
                  <th>Quote Description</th>
                  <th>Date Created</th>
                  <th>Status</th>
                  <th>Amount</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredQuotes.map((quote) => {
                  const badgeClass =
                    badgeQuoteClassMap[quote.statusID] || "badge-light-secondary";

                  return (
                    <tr key={quote.quoteId}>
                      <td>
                        <a
                          href={`/quotes/edit/${quote.quoteId}`}
                          onClick={(event) => handleQuoteClick(quote.quoteId, event)}
                          className="text-gray-600 text-hover-primary"
                        >
                          {quote.quoteId}
                        </a>
                      </td>
                      <td></td>
                      <td></td>
                      <td>
                        {quote.quoteDate
                          ? new Date(quote.quoteDate).toLocaleDateString()
                          : "N/A"}
                      </td>
                      <td>
                        <span className={`badge ${badgeClass}`}>
                          {quote.statusName || "Unknown"}
                        </span>
                      </td>
                      <td>${quote.totalAmount.toFixed(2)}</td>
                      <td className="text-end">
                        <button className="btn btn-sm btn-light">Edit</button>
                        <button className="btn btn-sm btn-light text-danger">
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
                {filteredQuotes.length === 0 && (
                  <tr>
                    <td colSpan={7} className="text-center">
                      No quotes found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuotesGrid;
