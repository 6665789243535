import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from '../../Components/auth/authContext';
import { useUser } from "../../Components/context/UserContext";
import NoCustomers from "./components/noCustomers";
import CustomersGrid from "./components/CustomersGrid";
import { Outlet } from "react-router-dom";
import { getCustomers, Customer } from '../../api/customersAPI';
import { useLoading } from "../../Components/context/LoadingContext";

const CustomersPage: React.FC = () => {
  const currentUser = useContext(AuthContext);
  const { userIdentity } = useUser();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const { setLoading } = useLoading();

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        setLoading(true);
        const response = await getCustomers(userIdentity?.accountId ?? 0, 1, 5);
        setCustomers(response.customers || []);
      } catch (error) {
        console.error("Failed to fetch customers", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomers();
  }, []);
  
  return (
    <div id="kt_app_content" className="app-content flex-column-fluid">
      <div id="kt_app_content_container" className="app-container container-xxl">
        {customers.length === 0 ? <NoCustomers /> : <Outlet />}
      </div>
    </div>
  );
};

export { CustomersPage };
