import React, { useEffect, useState } from "react";

interface AlertProps {
  error?: string | null;
  success?: string | null;
  successTimeout?: number; // Optional prop for success timeout in seconds
}

const AlertMessage: React.FC<AlertProps> = ({
  error,
  success,
  successTimeout = 3, // Default timeout is 3 seconds
}) => {
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (success) {
      setShowSuccess(true);
      const timer = setTimeout(() => {
        setShowSuccess(false);
      }, successTimeout * 1000); // Convert seconds to milliseconds

      return () => clearTimeout(timer);
    }
  }, [success, successTimeout]);

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  if (showSuccess) {
    return <div className="alert alert-success">{success}</div>;
  }

  return null;
};

export default AlertMessage;