    // Function to get today's date in yyyy-MM-dd format
    export const getTodayDate = (): string => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };
    
// Function to get a future date in yyyy-MM-dd format
export const getFutureDate = (daysToAdd: number): string => {
    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + daysToAdd);
    const year = futureDate.getFullYear();
    const month = String(futureDate.getMonth() + 1).padStart(2, "0");
    const day = String(futureDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
};