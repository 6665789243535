import React from "react";
import { QuoteItem } from "../../../api/quoteApi";

interface ItemsTableProps {
  items: QuoteItem[];
  onAdd: (event: React.MouseEvent<HTMLButtonElement>) => void; 
  onRemove: (index: number) => void;
  onChange: (index: number, field: keyof QuoteItem, value: string | number) => void;
}

const QuoteFormItemsTable: React.FC<ItemsTableProps> = ({
  items,
  onAdd,
  onRemove,
  onChange,
}) => {
  const calculateTotal = () => {
    return items.reduce((acc, item) => acc + item.quantity * item.price, 0);
  };

  return (
    <div className="table-responsive mb-10">
      <table
        className="table g-5 gs-0 mb-0 fw-bold text-gray-700"
        data-kt-element="items"
        aria-label="Quote items table"
      >
        <thead>
          <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
            <th className="min-w-300px w-475px">Item</th>
            <th className="min-w-100px w-100px">QTY</th>
            <th className="min-w-150px w-150px">Price</th>
            <th className="min-w-100px w-150px text-end">Total</th>
            <th className="min-w-75px w-75px text-end">Action</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr
              key={index}
              className="border-bottom border-bottom-dashed"
              data-kt-element="item"
            >
              <td className="pe-7">
                <input
                  type="text"
                  className="form-control form-control-solid mb-2"
                  name="name[]"
                  placeholder="Item name"
                  value={item.itemName}
                  onChange={(e) => onChange(index, "itemName", e.target.value)}
                />
                <input
                  type="text"
                  className="form-control form-control-solid"
                  name="description[]"
                  placeholder="Description"
                  value={item.itemDescription}
                  onChange={(e) =>
                    onChange(index, "itemDescription", e.target.value)
                  }
                />
              </td>
              <td className="ps-0">
                <input
                  className="form-control form-control-solid"
                  type="number"
                  min={1}
                  name="quantity[]"
                  placeholder="1"
                  value={item.quantity}
                  onChange={(e) =>
                    onChange(index, "quantity", parseInt(e.target.value, 10))
                  }
                  data-kt-element="quantity"
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control form-control-solid text-end"
                  name="price[]"
                  placeholder="0.00"
                  value={item.price}
                  onChange={(e) =>
                    onChange(index, "price", parseFloat(e.target.value))
                  }
                  data-kt-element="price"
                />
              </td>
              <td className="pt-8 text-end text-nowrap">
                $
                <span data-kt-element="total">
                  {(item.quantity * item.price).toFixed(2)}
                </span>
              </td>
              <td className="pt-5 text-end">
                <button
                  type="button"
                  className="btn btn-sm btn-icon btn-active-color-primary"
                  data-kt-element="remove-item"
                  onClick={() => onRemove(index)}
                >
                  <i className="ki-duotone ki-trash fs-3">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                  </i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className="border-top border-top-dashed align-top fs-6 fw-bold text-gray-700">
            <th className="text-primary">
              <button className="btn btn-link py-1" onClick={onAdd}>
                Add item
              </button>
            </th>
            <th
              colSpan={2}
              className="border-bottom border-bottom-dashed ps-0"
            >
              <div className="d-flex flex-column align-items-start">
                <div className="fs-5">Subtotal</div>
                <button
                  className="btn btn-link py-1"
                  data-bs-toggle="tooltip"
                  data-bs-trigger="hover"
                  title="Coming soon"
                >
                  Add tax
                </button>
                <button
                  className="btn btn-link py-1"
                  data-bs-toggle="tooltip"
                  data-bs-trigger="hover"
                  title="Coming soon"
                >
                  Add discount
                </button>
              </div>
            </th>
            <th
              colSpan={2}
              className="border-bottom border-bottom-dashed text-end"
            >
              $
              <span data-kt-element="sub-total">{calculateTotal().toFixed(2)}</span>
            </th>
          </tr>
          <tr className="align-top fw-bold text-gray-700">
            <th></th>
            <th colSpan={2} className="fs-4 ps-0">
              Total
            </th>
            <th colSpan={2} className="text-end fs-4 text-nowrap">
              $
              <span data-kt-element="grand-total">
                {calculateTotal().toFixed(2)}
              </span>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default QuoteFormItemsTable;