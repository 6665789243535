import React from "react";

// PaymentDetails Component
const PaymentDetails = () => (
    <div className="d-print-none border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten">
      <div className="mb-8">
        <span className="badge badge-light-success me-2">Approved</span>
        <span className="badge badge-light-warning">Pending Payment</span>
      </div>
      <h6 className="mb-8 fw-bolder text-gray-600 text-hover-primary">
        PAYMENT DETAILS
      </h6>
      <div className="mb-6">
        <div className="fw-semibold text-gray-600 fs-7">Paypal:</div>
        <div className="fw-bold text-gray-800 fs-6">codelabpay@codelab.co</div>
      </div>
      <div className="mb-6">
        <div className="fw-semibold text-gray-600 fs-7">Account:</div>
        <div className="fw-bold text-gray-800 fs-6">
          Nl24IBAN34553477847370033 <br />
          AMB NLANBZTC
        </div>
      </div>
      <div className="mb-15">
        <div className="fw-semibold text-gray-600 fs-7">Payment Term:</div>
        <div className="fw-bold fs-6 text-gray-800 d-flex align-items-center">
          14 days
          <span className="fs-7 text-danger d-flex align-items-center">
            <span className="bullet bullet-dot bg-danger mx-2"></span>
            Due in 7 days
          </span>
        </div>
      </div>
      <h6 className="mb-8 fw-bolder text-gray-600 text-hover-primary">
        PROJECT OVERVIEW
      </h6>
      <div className="mb-6">
        <div className="fw-semibold text-gray-600 fs-7">Project Name</div>
        <div className="fw-bold fs-6 text-gray-800">
          SaaS App Quickstarter
          <a href="#" className="link-primary ps-1">
            View Project
          </a>
        </div>
      </div>
      <div className="mb-6">
        <div className="fw-semibold text-gray-600 fs-7">Completed By:</div>
        <div className="fw-bold text-gray-800 fs-6">Mr. Dewonte Paul</div>
      </div>
      <div className="m-0">
        <div className="fw-semibold text-gray-600 fs-7">Time Spent:</div>
        <div className="fw-bold fs-6 text-gray-800 d-flex align-items-center">
          230 Hours
          <span className="fs-7 text-success d-flex align-items-center">
            <span className="bullet bullet-dot bg-success mx-2"></span>
            35$/h Rate
          </span>
        </div>
      </div>
    </div>
  );

  export default PaymentDetails;